//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CustomerLevel, CustomerLevelDelete, CustomerLevelCreate, CustomerLevelUpdate } from '@/api/updateUserInfo/notice';
import Head from '@/components/head/index';
export default {
  name: 'Notice',
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      menuId: localStorage.getItem('menuId'),
      // 系统教程
      title: '系统配置',
      username: '',
      dialogFormVisible: false,
      tableData: [],
      textMap: '',
      hide: '2',
      num: '',
      id: '',
      HideVal: '0',
      level_name: '',
      level_nameEs: '',
      IsHide: [{
        value: '0',
        label: '全部'
      }, {
        value: '1',
        label: '是'
      }, {
        value: '2',
        label: '否'
      }]
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.getList();
    } else {}
  },
  components: {
    Head: Head
  },
  methods: {
    getList: function getList() {
      var _this = this;
      //列表
      CustomerLevel({
        level_name: this.level_name,
        is_hide: this.HideVal
      }).then(function (respomse) {
        _this.tableData = respomse.data;
      });
    },
    HideChange: function HideChange() {
      this.getList();
    },
    popup: function popup(num, id, namu, is_hide) {
      //新建、编辑弹窗
      this.num = num;
      this.id = String(id);
      if (num == 1) {
        this.textMap = '新建学员级别';
        this.hide = '2';
      } else if (num == 2) {
        this.textMap = '新建子级';
        this.hide = '2';
      } else if (num == 3) {
        this.textMap = '编辑学员级别';
        this.level_name = namu;
        this.hide = String(is_hide);
      }
      this.dialogFormVisible = true;
    },
    resetTemps: function resetTemps(num, id) {
      var _this2 = this;
      //新建、编辑列表
      if (num == 1) {
        // 新建学员级别
        this.textMap = '新建学员级别';
        CustomerLevelCreate({
          level_name: this.level_name,
          is_hide: this.hide
        }).then(function (respomse) {
          _this2.$notify({
            title: '成功',
            message: '新建学员级别成功',
            type: 'success'
          });
          _this2.level_name = '';
          _this2.dialogFormVisible = false;
          _this2.getList();
        });
      } else if (num == 2) {
        // 新建子级
        this.textMap = '新建子级';
        CustomerLevelCreate({
          id: id,
          level_name: this.level_name,
          is_hide: this.hide
        }).then(function (respomse) {
          _this2.$notify({
            title: '成功',
            message: '新建子级成功',
            type: 'success'
          });
          _this2.level_name = '';
          _this2.dialogFormVisible = false;
          _this2.getList();
        });
      } else if (num == 3) {
        // 编辑学员级别
        this.textMap = '编辑学员级别';
        CustomerLevelUpdate({
          id: id,
          level_name: this.level_name,
          is_hide: this.hide
        }).then(function (respomse) {
          _this2.$notify({
            title: '成功',
            message: '编辑学员级别成功',
            type: 'success'
          });
          _this2.level_name = '';
          _this2.dialogFormVisible = false;
          _this2.getList();
        });
      }
    },
    emptyEs: function emptyEs() {
      //清空弹窗
      this.level_name = '';
      this.hide = 2;
      this.id = '';
      this.num = '';
      this.dialogFormVisible = false;
    },
    deleteBtn: function deleteBtn(id) {
      var _this3 = this;
      //删除子级列表
      this.$confirm('此操作将永久删除学员级别，是否继续？', '删除学员级别', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        CustomerLevelDelete({
          id: String(id)
        }).then(function (respomse) {
          _this3.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          });
          _this3.getList();
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    queryList: function queryList(val) {
      this.level_name = this.username;
      this.getList();
    }
  }
};